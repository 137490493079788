import { RouterView, createRouter, createWebHistory } from 'vue-router';
import { routeMiddleware } from '@/i18n';
import { commonQueryParamMiddleware } from '@/features/common/stores';
import { LandingPage } from '@/pages';

export const routeNames = {
    home: 'home',
    notFound: 'not-found',
    tickets: 'tickets',
    cards: 'cards',
    addTravelMoney: 'add-travelmoney',
    checkout: 'checkout',
    orders: 'orders',
    orderDetails: 'order-details',
    checkoutPaymentVerification: 'checkout-payment-verification',
    travelcardPaymentVerification: 'travelcard-payment-verification',
    checkoutSuccess: 'checkout-success',
    checkoutSuccessTravelcard: 'checkout-success-travelcard',
    checkoutFailure: 'checkout-failure',
    orderTravelCard: 'order-travelcard'
};

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:locale?',
            component: RouterView,
            children: [
                {
                    name: routeNames.home,
                    path: '',
                    component: LandingPage
                },
                {
                    name: routeNames.tickets,
                    path: 'tickets',
                    component: () => import('@/pages/TicketSelectionPage.vue')
                },
                {
                    name: routeNames.cards,
                    path: 'cards',
                    component: () => import('@/pages/CardSelectionPage.vue')
                },
                {
                    name: routeNames.addTravelMoney,
                    path: 'add-travelmoney',
                    component: () => import('@/pages/AddTravelMoneyPage.vue')
                },
                {
                    name: routeNames.checkout,
                    path: 'checkout',
                    component: () => import('@/pages/checkout/CheckoutPage.vue')
                },
                {
                    name: routeNames.checkoutPaymentVerification,
                    path: 'checkout/paymentverification',
                    component: () => import('@/pages/checkout/CheckoutPaymentVerification.vue'),
                    props: route => ({
                        transactionId: route.query.transactionId,
                        responseCode: route.query.responseCode,
                        isTravelCardOrder: false
                    })
                },
                {
                    name: routeNames.travelcardPaymentVerification,
                    path: 'checkout/travelcardpaymentverification',
                    component: () => import('@/pages/checkout/CheckoutPaymentVerification.vue'),
                    props: route => ({
                        transactionId: route.query.transactionId,
                        responseCode: route.query.responseCode,
                        isTravelCardOrder: true
                    })
                },
                {
                    name: routeNames.checkoutSuccess,
                    path: 'checkout/success',
                    component: () => import('@/pages/checkout/CheckoutSuccess.vue'),
                    props: route => ({
                        orderId: route.query.orderId
                    })
                },
                {
                    name: routeNames.checkoutSuccessTravelcard,
                    path: 'checkout/success-travelcard',
                    component: () => import('@/pages/checkout/CheckoutSuccessTravelCard.vue'),
                    props: route => ({
                        orderId: route.query.orderId,
                        transactionId: route.query.transactionId
                    })
                },
                {
                    name: routeNames.checkoutFailure,
                    path: 'checkout/failure',
                    component: () => import('@/pages/checkout/CheckoutFailure.vue'),
                    props: route => ({
                        errorMessage: route.params.errorMessage,
                        orderNumber: route.query.orderNumber,
                        isTravelCardOrder: String(route.params.isTravelCardOrder).toLowerCase() === 'true'
                    })
                },
                {
                    name: routeNames.orders,
                    path: 'orders',
                    component: () => import('@/pages/OrdersPage.vue'),
                },
                {
                    name: routeNames.orderDetails,
                    path: 'orders/:orderId',
                    component: () => import('@/pages/OrderDetailsPage.vue'),
                    props: true
                },
                {
                    name: routeNames.orderTravelCard,
                    path: 'order-travelcard',
                    component: () => import('@/pages/OrderTravelCardPage.vue')
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: { name: routeNames.home }
        }
    ]
});

router.beforeEach(routeMiddleware);
router.beforeEach(commonQueryParamMiddleware);

export default router;
